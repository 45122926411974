<template>
    <main>
        <section
            class="flex items-center justify-center bg-center bg-cover h-96"
            style="background-image: url('/images/signup.jpg')"
        >
            <span class="max-w-xl text-center text-white standard-heading">
                SIGNUP
            </span>
        </section>

        <section class="flex flex-col items-center py-24">
            <div v-if="hasError" class="w-full max-w-xl px-8 mb-4">
                <ServerErrors :error="error" />
            </div>

            <div v-if="hasSuccess" class="w-full max-w-xl px-8 mb-4">
                <b-message type="is-info">
                    Success! You are now signed up.
                </b-message>
            </div>

            <Form v-slot="{ handleSubmit }" class="max-w-xl px-8">
                <div class="flex flex-row">
                    <TextInput
                        v-model="form.first_name"
                        name="first_name"
                        rules="required"
                        placeholder="First Name"
                        class=""
                    ></TextInput>

                    <TextInput
                        v-model="form.last_name"
                        name="last_name"
                        rules="required"
                        placeholder="Last Name"
                        class="ml-4"
                    ></TextInput>
                </div>

                <TextInput
                    v-model="form.business_name"
                    name="business_name"
                    rules="required"
                    placeholder="Business Name"
                    class="pt-4"
                ></TextInput>

                <TextInput
                    v-model="form.email"
                    name="email"
                    rules="required"
                    placeholder="Email"
                    class="pt-4"
                ></TextInput>

                <TextInput
                    v-model="form.phone"
                    name="phone"
                    rules="required"
                    placeholder="Phone Number"
                    class="pt-4"
                ></TextInput>

                <TextInput
                    v-model="form.address"
                    name="address"
                    rules="required"
                    placeholder="Address"
                    class="pt-4"
                ></TextInput>

                <div class="flex flex-row pt-4">
                    <TextInput
                        v-model="form.city"
                        name="city"
                        rules="required"
                        placeholder="City"
                        class=""
                    ></TextInput>

                    <Select
                        v-model="form.province"
                        name="province"
                        rules="required"
                        placeholder="Province"
                        class="ml-4"
                    >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NU">Nunavut</option>
                        <option value="YT">Yukon</option>
                    </Select>
                </div>

                <div class="flex justify-center w-full pt-4">
                    <button class="standard-button" :disabled="isSubmitting" @click="handleSubmit(submit)">
                        SIGN UP
                    </button>
                </div>
            </Form>
        </section>
    </main>
</template>

<script>
import axios from 'axios';
import ServerErrors from '../components/ServerErrors.vue';

const INITIAL = 'is-initial';
const SUBMITTING = 'is-submitting';
const SUCCESS = 'has-success';
const ERROR = 'has-error';

export default {
    name: 'Signup',

    components: {
        ServerErrors,
    },

    props: {},

    data() {
        return {
            state: INITIAL,
            form: {
                first_name: '',
                last_name: '',
                business_name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                province: '',
            },
            error: undefined,
        };
    },

    computed: {
        isSubmitting() {
            return (this.state === SUBMITTING);
        },

        hasSuccess() {
            return (this.state === SUCCESS);
        },

        hasError() {
            return (this.state === ERROR);
        },
    },

    methods: {
        async submit() {
            try {
                this.state = SUBMITTING;

                await axios.post('/signup', {
                    ...this.form,
                    name: `${this.form.first_name} ${this.form.last_name}`,
                });

                this.state = SUCCESS;
            } catch (err) {
                this.error = err.response.data;
                this.state = ERROR;
                throw new Error(`Signup# Problem submitting signup form: ${err}`);
            }
        },
    },

};
</script>
